.table-container {
    display: flex;
    justify-content: left;
    /* margin-top: 50px; */
    overflow: auto;
    width: 100%;
}

table {
  border-collapse: collapse;
  margin: 5px 0;
  font-size: 14px;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%
}

table thead tr {
  background-color: #2196F3; /* #4bbd7f; */
  color: #ffffff;
  text-align: center; /* changed to center from left */
}

table th,
table td {
    padding: 10px 5px; /* was 15px */
    border-right: 1px solid lightgray;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
  background-color: #f6f6f6;
}

table tbody tr:last-of-type {
  border-bottom: 2px solid #4bbd7f;
}

table tbody tr.active-row {
  font-weight: bold;
  color: #4bbd7f;
}

table td:first-child input {
 /* width: 100px;*/ /* 50px; */
}

table td:nth-child(2) input {
 /* width: 100px; *//* 120px; */
}

table td:nth-child(3) input {
/*    width: 100px; *//* 120px; */
  }

table .edit-cell-container button {
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 1px solid #ccc;
}

table .footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
}

table .add-button {
  color: #4bbd7f;
}

table .remove-button {
  color: #e44747;
}

table .footer-buttons button {
  border: none;
  background-color: transparent;
  background-color: green;
}

table .edit-cell-action button[name='edit'] {
  color: #ffb918;
}

table .edit-cell-action button[name='cancel'] {
  color: #7b7b7b;
}

table .edit-cell-action button[name='done'] {
  color: #4bbd7f;
}

table .edit-cell-action button[name='done']:disabled {
  color: #ccc;
}

table .edit-cell-action button[name='remove'] {
  /* color: red; */
  color: gray;
  padding: 0px;
/*   background-color: rgb(230, 208, 208); */
}

table input, select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

table .edit-cell-action {
    display: flex;
    gap: 5px;
}

table .edit-cell-container {
  display: flex;
  justify-content: center; /* change to 'end' to be right justified */
  align-items: center;
  gap: 4px;
  /* width: 30px; */
  min-width: 60px;
 /* max-width: 70px; */
}

table input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

select:invalid, input:invalid {
  border: 2px solid red;
}
/*
input[type='date'] {
  width: 200px;
  height: 16px;
  padding: 5px;
  margin: 10px;
}
*/
select:invalid, input:invalid {
  border: 2px solid red;
}

table .edit-cell-action button[name='done']:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}